import React, { useEffect, useState } from 'react'
import {
  AppConfigurationType,
  BridgeFormValues,
  AppIntegrationSetting,
} from 'Nbee'
import { ApiErrorResponse } from 'BackendApi'
import { AppSelector } from '@features/nbee/SimpleBridgeBuilderForm/fields/AppSelector'
import { useFormikContext } from 'formik'
import { IntegrationSelector } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSelector'
import { IntegrationSettings } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings'
import { useTestIntegrationCredentialsById } from '@app/api/testIntegrationCredentialsById'
import { useTranslation } from 'react-i18next'
import { usePopupError } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/usePopupError'

interface AppConfiguratorProps {
  type: AppConfigurationType
  canShowIntegrationField?: boolean
  templateSettings?: AppIntegrationSetting[]
}

export const AppConfigurator: React.FC<AppConfiguratorProps> = ({
  type,
  canShowIntegrationField,
  templateSettings,
}) => {
  const { t } = useTranslation()
  // some formik helpers
  const { values, setFieldValue, initialValues, setSubmitting } =
    useFormikContext<BridgeFormValues>()
  const selectedAppId = values[type].appId
  const parentAppId = values[type].parentAppId
  const selectedIntegrationId = values[type].integrationId

  // test integration once selected
  const {
    data: isValidIntegrationResponse,
    error: isValidIntegrationApiError,
    isLoading: isCheckingValidIntegration,
    isRefetching: isReCheckingValidIntegration,
    refetch: reCheckValidIntegration,
  } = useTestIntegrationCredentialsById(selectedIntegrationId, selectedAppId)

  const isTestingIntegrationCredentials =
    isCheckingValidIntegration || isReCheckingValidIntegration

  const isValidIntegration = Boolean(isValidIntegrationResponse?.data?.result)
  // handling error in case isValidIntegrationResponse retuns false
  const { showPopupError, clearPopupError, setIntegrationId } = usePopupError({
    type,
    showLogo: true,
  })

  useEffect(() => {
    clearPopupError()
    if (isValidIntegrationApiError && selectedIntegrationId) {
      setIntegrationId(selectedIntegrationId)
      showPopupError(isValidIntegrationApiError)
    }

    // Back-end does not return an error message in case integration is not valid,
    // instead it returns a 200 reponse with result: false, so in order to achieve same
    // behaviour of the 'integration-error'  we need to simulate the ApiErrorResponse structure
    if (isValidIntegrationResponse?.data.result === false) {
      const popupErrorFake = {
        response: {
          data: {
            error: {
              code: 'integration-error',
              message:
                isValidIntegrationResponse.data.message ||
                t('nbee.bridgeBuilder.validation.integrationApiError'),
            },
          },
        },
      } as unknown as ApiErrorResponse
      showPopupError(popupErrorFake)
    }
  }, [isValidIntegrationResponse, isValidIntegrationApiError])

  const [localTemplateSettings, setLocalTemplateSettings] = useState<
    AppIntegrationSetting[]
  >(templateSettings || [])

  // we need to disable submit while testing integration
  useEffect(() => {
    setSubmitting(isTestingIntegrationCredentials)
  }, [isTestingIntegrationCredentials])

  // reset integrationId when appId changes
  // TODO: if user changes appId and then changes it back to the previous one, the initialValues are not restored
  useEffect(() => {
    if (!selectedAppId || selectedAppId !== initialValues[type].appId) {
      setFieldValue(`${type}.integrationId`, undefined)
      setLocalTemplateSettings([])
    }
  }, [selectedAppId])

  // when integrationID changes we need to empty the integration settings
  useEffect(() => {
    const isIntegrationIdChanged =
      !selectedIntegrationId ||
      selectedIntegrationId !== initialValues[type].integrationId
    const settingsAreFilled = values[type].settings?.length
    if (isIntegrationIdChanged && settingsAreFilled) {
      if (localTemplateSettings?.length) {
        // Special case: reset settings to template settings, if we have them
        setFieldValue(`${type}.settings`, localTemplateSettings)
      } else {
        // Normal case: reset settings
        console.log('emptying integration settings (AppConfigurator)')
        setFieldValue(`${type}.settings`, [])
      }
    }
  }, [selectedIntegrationId])

  const bothAppsAreSelected =
    Number.isInteger(values.source.appId) &&
    Number.isInteger(values.destination.appId) &&
    canShowIntegrationField

  const appHasSetting = values[type].settings?.length

  return (
    <>
      <h1>
        {type === 'source'
          ? t('nbee.bridgeBuilder.sourceTitle')
          : t('nbee.bridgeBuilder.destinationTitle')}
      </h1>
      <h4>
        {type === 'source'
          ? t('nbee.bridgeBuilder.sourceSubTitle')
          : t('nbee.bridgeBuilder.destinationSubTitle')}
      </h4>

      {/* Select app */}
      <AppSelector type={type} />

      {/* Select integration/connection  */}
      {bothAppsAreSelected || appHasSetting ? (
        <IntegrationSelector
          type={type}
          appId={selectedAppId!}
          parentAppId={parentAppId}
          isTesting={
            !!(selectedIntegrationId && isTestingIntegrationCredentials)
          }
          onTestIntegrationRequest={() => {
            reCheckValidIntegration()
          }}
        />
      ) : null}

      {/* Dynamic settings returned by api */}
      <IntegrationSettings
        type={type}
        isValidIntegration={isValidIntegration}
        templateSettings={localTemplateSettings}
      />
    </>
  )
}
